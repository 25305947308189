import "./styles.scss"

import React from "react"

import IconBox from "components/IconBox"

const Garden = () => {
  return (
    <section
      className="convenients-garden"
      style={{
        backgroundImage: `url(${
          require("assets/images/convenients/garden-image.jpg").default
        })`,
      }}
    >
      <div
        className="convenients-garden__thumbnail"
        style={{
          backgroundImage: `url(${
            require("assets/images/convenients/garden-image.jpg").default
          })`,
        }}
      >
        <p>
          <span>500m²</span> ogrodu
        </p>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xxl-5 col-lg-6">
            <div className="convenients-garden__content">
              <IconBox
                icon={
                  require("assets/images/convenients/garden-icon.svg").default
                }
              />
              <h2>UROKLIWY OGRÓD</h2>
              <p>
                Mazowiecka115 to nie tylko komfortowe wnętrza, ale też
                ekskluzywna przestrzeń zewnętrzna. Urokliwy ogród o powierzchni
                500 m2 jest zieloną enklawą w centrum wielkiego miasta. To
                idealne miejsce do relaksu na świeżym powietrzu. Umożliwia
                spędzanie czasu przyjemnie i zdrowo. Wyznaczone ścieżki
                zachęcają do spacerów, a ławki wśród zadbanych trawników
                pozwalają odpocząć np. z książką w ręku. Ogród zadowoli dzieci,
                dorosłych i seniorów. To także kolejne miejsce nadające się do
                spotkań sąsiedzkich. Zakątki pełne słońca, ale i te zacienione
                mogą stać się Twoją własną oazą spokoju.
              </p>
            </div>
          </div>
          <div className="col-xxl-7 col-lg-6">
            <div className="convenients-garden__area">
              <p>
                <span>500m²</span>
                <br />
                ogrodu
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Garden
