import React from "react"

import Layout from "components/Layout"
import { Hero, Content, Garden } from "page_components/convenients"

const content_data = [
  {
    image: require("assets/images/convenients/content-image-1.jpg").default,
    icon: require("assets/images/convenients/content-icon-1.svg").default,
    title: "STREFA RELAKSU",
    description:
      "<p>Starannie zaprojektowana, nowoczesna <strong>strefa relaksu</strong> to wyjątkowe miejsce, w którym Mazowiecka115 pozwala wyciszyć się i naładować baterie. Eleganckie wnętrza staną się Twoją oazą spokoju po intensywnym dniu. Znajdziesz tu warunki do odzyskania wewnętrznej harmonii, a także do medytacji oraz regeneracji ducha i ciała.</p>",
  },
  {
    image: require("assets/images/convenients/content-image-2.jpg").default,
    description:
      "<p>Ściana solna, obok walorów estetycznych, umożliwia wyciszenie się oraz ma na nas wpływ zdrowotny. Poza specyficznym klimatem wprost z groty solnej, możesz skorzystać z wewnętrznej tężni solankowej! Bardziej aktywni znajdą tu przestrzeń do jogi, fitnessu i innych form aktywności fizycznej.</p>",
    isReverse: true,
  },
  {
    image: [
      require("assets/images/convenients/slider/convenient-image-1.jpg")
        .default,
      require("assets/images/convenients/slider/convenient-image-2.jpg")
        .default,
      require("assets/images/convenients/slider/convenient-image-3.jpg")
        .default,
    ],
    icon: require("assets/images/convenients/content-icon-2.svg").default,
    title: "POKÓJ KLUBOWY",
    description:
      "<p><strong>Pokój klubowy,</strong> common room lub po prostu wspólna przestrzeń rekreacyjna to wyjątkowe miejsce w budynku Mazowiecka115. Zostało stworzone z myślą o mieszkańcach mających szczególne wymagania. Możesz wykorzystać je do pracy, gdy nie jesteś w stanie skupić się we własnym mieszkaniu. To doskonała opcja, jeśli nie znajdujesz dogodnych warunków wśród głośnych domowników lub gdy nie chcesz przeszkadzać rodzinie swoimi rozmowami służbowymi. To tutaj masz szansę na chwilę spokoju i skupienia. Ta część wspólna rewelacyjnie sprawdzi się także jako miejsce spotkań sprzyjające życiu towarzyskiemu i sąsiedzkiej integracji. Nowoczesne, stylowe wnętrze zachęca też do owocnego spędzania czasu w pojedynkę, oddając się czytaniu książki lub innej pasji.</p>",
    hasBackground: true,
    isFull: true,
  },
  {
    image: require("assets/images/convenients/content-image-3.jpg").default,
    icon: require("assets/images/convenients/content-icon-3.svg").default,
    title: "CAŁODOBOWA RECEPCJA",
    description:
      "<p>Nasza <strong>całodobowa recepcja</strong> to gwarancja bezpieczeństwa i komfortu o każdej porze dnia i nocy. Profesjonalny personel recepcyjny dba o prywatność mieszkańców, udostępniając budynek tylko wybranym osobom. Oferuje też pomoc w codziennych sprawach, jak choćby odbiór przesyłek. Stały dostęp do osób, które chętnie Ci pomogą, to podstawa obsługi na najwyższym poziomie!</p>",
    isReverse: true,
    isSpaced: true,
  },
]

const Convenients = () => (
  <Layout seo={{ title: "Udogodnienia" }}>
    <Hero />
    {content_data.map((item, index) => (
      <Content key={index} {...item} />
    ))}
    <Garden />
  </Layout>
)

export default Convenients
