import "./styles.scss"

import React from "react"
import Slider from "react-slick"

import IconBox from "components/IconBox"

const Content = ({
  image,
  icon,
  title,
  description,
  hasBackground,
  isReverse,
  isSpaced,
  isFull,
}) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 750,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
  }

  return (
    <section
      className={`convenients-content${
        hasBackground ? " convenients-content--background" : ""
      }${isReverse ? " convenients-content--reverse" : ""}${
        isSpaced ? " convenients-content--spaced" : ""
      }${isFull ? " convenients-content--full" : ""}`}
    >
      <div className={!isFull ? "container-fluid" : ""}>
        <div className={`row${isReverse ? " flex-lg-row-reverse" : ""}`}>
          <div className="col-xl-7 col-lg-6">
            {image ? (
              Array.isArray(image) ? (
                <Slider {...settings}>
                  {image.map((item, index) => (
                    <div key={index}>
                      <div
                        className="convenients-content__image"
                        style={{
                          backgroundImage: `url(${item})`,
                        }}
                      />
                    </div>
                  ))}
                </Slider>
              ) : (
                <div
                  className="convenients-content__image"
                  style={{
                    backgroundImage: `url(${image})`,
                  }}
                />
              )
            ) : null}
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="convenients-content__body">
              <div className="convenients-content__body-inner">
                {icon && <IconBox icon={icon} />}
                {title && <h2>{title}</h2>}
                {description && (
                  <div
                    className="convenients-content__description"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Content
