import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

const Hero = () => {
  return (
    <section className="convenients-hero">
      <div className="container-fluid">
        <motion.h1
          initial={{ y: 24, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ type: "tween", duration: 0.5, delay: 0.1 }}
        >
          UDOGODNIENIA
        </motion.h1>
        <motion.div
          className="convenients-hero__wrapper"
          initial={{ y: -24, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ type: "tween", duration: 0.5, delay: 0.1 }}
        >
          <h2>
            <span>
              {"MAKSYMALNY KOMFORT ŻYCIA".split(" ").map((item, index) => (
                <span key={index}>{item}</span>
              ))}
            </span>
          </h2>
          <p>
            Mazowiecka115 to kwintesencja luksusu i wygody. Atrakcyjny budynek w
            dogodnej lokalizacji oferuje ustawne, ergonomicznie urządzone
            wnętrza, które zachwycają nowoczesnym designem. Starannie opracowany
            projekt fantastycznie prezentuje się z zewnątrz, a do tego spełnia
            oczekiwania użytkowe najbardziej wymagających klientów. Wykończone w
            wysokim standardzie mieszkania są miejscem idealnym dla człowieka
            chcącego czerpać z życia to, co najlepsze!
          </p>
        </motion.div>
      </div>
    </section>
  )
}

export default Hero
